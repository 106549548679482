<template>
  <div>
    <top-menu />
    <router-view />
  </div>
</template>

<script>
import TopMenu from '@/components/TopMenu'
export default {
  components: { TopMenu }
}
</script>

<style>

</style>
